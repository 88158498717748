
import React from 'react';
import {number, ReferenceInput, ReferenceArrayInput, SelectArrayInput, TextInput, Create, SimpleForm, SelectInput, required, useTranslate} from 'react-admin';
// import { DateInput } from 'react-admin-date-inputs2';
// import { DateInput } from 'react-admin-date-inputs2';
// import DateFnsUtils from '@date-io/date-fns';
// import MomentUtils from 'material-ui-pickers/utils/moment-utils';
// import frLocale from "date-fns/locale/fr";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import CardActions from '@material-ui/core/CardActions';
import { ListButton} from 'react-admin';
// DateFnsUtils.prototype.getStartOfMonth = DateFnsUtils.prototype.startOfMonth
import {orderTypes, unitTypes} from '../../util/constant'
import {activityTypes, orderActivityTypes} from '../../util/variant'

const useStyles = makeStyles({
    inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
    
});
const cardActionStyle = {
    zIndex: 2,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    padding: 0,
};

// import Strings from '../res/strings';
const Title = ({ record }) => {
    const translate = useTranslate();
    return <span>{translate('resources.price.create')}</span>;
};

const CreateActions = ({ basePath, data, resource }) => (
    <CardActions style={cardActionStyle}>
        <ListButton basePath={basePath}/>
    </CardActions>
);

const validatePricePositive = (value, allValues) => {
    if (value <= 0) {
        return 'Doit être plus grand que 0';
    }
    return [];
};

const validatePriceNotNegative = (value, allValues) => {
    if (value < 0) {
        return 'Doit ne pas être moins que 0';
    }
    return [];
};

const validatePrice = [required(), number(), validatePricePositive];
const validatePriceAdditional = [required(), number(), validatePriceNotNegative];
const validatePriceOptional = [number(), validatePriceNotNegative];
// var activityTypes = [];
// activityTypes.push({id: ACTIVITY_TYPE_TAXI, name: "Taxi"});
// activityTypes.push({id: ACTIVITY_TYPE_VTC, name: "VTC"});
// activityTypes.push({id: ACTIVITY_TYPE_TPMR, name: "TPMR"});

const CreatePrice = props => {
    const classes = useStyles();
    return (
    
    <Create title={<Title/>} {...props} actions={<CreateActions/>}>
        <SimpleForm redirect="list">
        <span class="MuiTypography-root MuiTypography-body2">Remarque: Si un triplet Type de commande, Type de véhicule, Zone apparaissent dans multiple configurations, alors la dernière mise à jour sera appliquée</span>
        <ReferenceArrayInput label="Types de véhicule" reference="vehicle_type" source="vehicle_type_ids">
                        <SelectArrayInput optionText="name">
                    {/* <ChipField source="name" /> */}
                </SelectArrayInput>
                    </ReferenceArrayInput>
                    <SelectInput source="order_type" label="Type de commande" validate={required()} 
                            choices={orderTypes} className={classes.inlineBlock}/>
                        
                        <ReferenceArrayInput label="Zones" reference="zone" source="zone_ids">
                        
                <SelectArrayInput optionText="name">
                    {/* <ChipField source="name" /> */}
                </SelectArrayInput>
            </ReferenceArrayInput>
                        
                            {/* <TextInput source="from_km" label="Rayons de (km)" validate={validatePriceAdditional}/>
                        <TextInput source="to_km" label="Rayons à (km)" validate={validatePriceAdditional}/> */}
                        
                        <div/>
                        
                        {/* <ReferenceInput source="vehicle_type_id" perPage={99999} className={classes.inlineBlock}
                        reference="vehicle_type" label="Type de véhicule" disabled>
                            <SelectInput optionText="name"/>
                        </ReferenceInput> */}
                        {/* <ReferenceInput source="vehicle_class_id" perPage={99999} className={classes.inlineBlock}
                        reference="vehicle_class" label="Gamme de véhicule" disabled>
                            <SelectInput optionText="name"/>
                        </ReferenceInput> */}

                        {/* <TextInput source="base" label="Base(€)" validate={validatePriceAdditional} className={classes.inlineBlock}/> */}
                        <div/>
                        <TextInput source="min" label="Minimum (€)" validate={validatePriceAdditional} className={classes.inlineBlock}/>
                        {/* <TextInput source="min_sub" label="Minimum abonné (€)" validate={validatePriceAdditional} className={classes.inlineBlock}/> */}
                        <div/>
                        <TextInput source="per_km" label="Par km (€)" validate={validatePriceAdditional} className={classes.inlineBlock}/>
                        {/* <TextInput source="per_km_sub" label="Par km abonné (€)" validate={validatePriceAdditional} className={classes.inlineBlock}/> */}
                        <div/>
                        <TextInput source="per_minute" label="Par minute (€)" validate={validatePriceAdditional} className={classes.inlineBlock}/>
                    
                        {/* <TextInput source="per_minute_sub" label="Par minute abonné (€)" validate={validatePriceAdditional} className={classes.inlineBlock}/> */}
                        <div/>
                        {/* <TextInput source="wait" label="Frais d'attente (€/minute)" validate={validatePriceAdditional}/>  */}
                        {/* <TextInput source="free_wait_time" label="Attente gratuite (minute)" validate={validatePriceAdditional}/>  */}
                        {/* <TextInput source="cancel" label="Frais d'annulation" validate={validatePriceOptional}/> */}
                        {/* <SelectInput source="cancel_unit" label="Unité du frais d'annulation" choices={unitTypes}/> */}
                        {/* <TextInput source="max_cancel" label="Plafond du frais d'annulation" validate={validatePriceOptional}/> */}
        </SimpleForm>
    </Create>
)};

export default CreatePrice;

{/* options={{ format: 'dd/MM/yyyy' }  */}