import React from 'react';
import {
    BooleanInput,
    ArrayInput, SimpleFormIterator, Edit, NumberInput, TextInput, number, TextField, SimpleForm, required, TabbedForm,
    FormTab, useTranslate
} from 'react-admin';
// import RichTextInput from 'ra-input-rich-text';
import Button from '@material-ui/core/Button';
import { ShowButton, SaveButton, Toolbar, TopToolbar } from 'react-admin';
import Typography from '@material-ui/core/Typography';

const EditActions = ({ basePath, data, resource }) => (
    <TopToolbar>
        {/* <ShowButton basePath='/view-my-profile' record={data} /> */}
        {/* <Button color="primary" onClick={customAction}>Custom Action</Button> */}
    </TopToolbar>
);

const EditToolbar = props => (
    <Toolbar {...props} >
        <SaveButton
            label="Enregistrer"
            redirect="/config"
            submitOnEnter={true}
        />

    </Toolbar>
);

const Title = ({ record }) => {
    const translate = useTranslate();
    return <span>{translate('menu.settings')}</span>;
};

const validateNotSmaller60 = (value, allValues) => {
    if (value < 60) {
        return 'Doit ne pas être moins que 60';
    }
    return [];
};

const validateNotSmaller5NotGreater55 = (value, allValues) => {
    if (value < 5 || value > 55) {
        return "Doit être dans l'interval de 5 minutes à 55 minutes";
    }
    return [];
};

const validateNotSmaller45 = (value, allValues) => {
    if (value <= 45) {
        return "Doit être supérieur que 45";
    }
    return [];
};

const validatePositive = (value, allValues) => {
    if (value <= 0) {
        return 'Doit être > 0';
    }
    return [];
};

const validateNotPositive = (value, allValues) => {
    if (value < 0) {
        return 'Doit être >=0 ';
    }
    return [];
};

// const validateGreaterThanZero = (value, allValues) => {
//     if (value < 1) {
//         return 'Doit être au moins 1';
//     }
//     return [];
// };

const validatePositiveNumber = [required(), number(), validatePositive];
const validateNotNegativeNumber = [required(), number(), validateNotPositive];
const validateNotSmallerThan60 = [required(), number(), validateNotSmaller60];
const validateRedispatch = [required(), number(), validateNotSmaller5NotGreater55];
// const validateMaxCount = [required(), number(), validateGreaterThanZero];

// const EditConfig = ({ staticContext, ...props }) => {
const EditConfig = props => {
    return (
        <Edit
            actions={<EditActions />}
            id='noid'
            resource="config"
            basePath="/config"
            redirect="false"
            title={<Title />}
            {...props}
        >

            {/* <SimpleForm redirect="list">
                
            <TextInput source="currency_code" label="Code de monnaie"/>
            

        </SimpleForm> */}
            <TabbedForm toolbar={<EditToolbar />}>
                <FormTab label="Générale">
                    {/* <TextInput source="currency_code" label="Code de monnaie"/>
                <TextInput source="currency_symbol" label="Symbole de monnaie"/> */}
                    <NumberInput source="delete_delay" label="Suppression permanente du compte (jours)" validate={validateNotNegativeNumber} style={{ 'width': '600px' }} />
                    <NumberInput source="commission" label="Commission service (%)" validate={validateNotNegativeNumber} style={{ 'width': '600px' }} />
                    <NumberInput source="commission_added" label="Commission service complémentaire (€)" validate={validateNotNegativeNumber} style={{ 'width': '600px' }} />
                    <NumberInput source="vat_2" label="TVA Auto-entrepreneur (%)" validate={validateNotNegativeNumber} />
                    <NumberInput source="vat_6" label="TVA Autre (%)" validate={validateNotNegativeNumber} />
                    {/* <NumberInput source="vat" label="TVA (%)" validate={validateNotNegativeNumber}/> */}
                    <TextInput source="center_lat" label="Centre Strasbourg - Latitude" style={{ 'width': '600px' }} />
                    <TextInput source="center_lng" label="Centre Strasbourg - Longitude" style={{ 'width': '600px' }} />
                    <TextInput source="return_point" label="Lieu de l'attente des chauffeurs" style={{ 'width': '800px' }} />
                    {/* <TextInput source="text_client_2" label="Textes au client pour Van" multiline style={{'width':'600px'}}/>
                <TextInput source="text_client_3" label="Textes au client pour Eco" multiline style={{'width':'600px'}}/> */}
                    {/* <NumberInput source="markup" label="Majoration altitude (Fcpf)" validate={validateNotNegativeNumber} style={{'width':'400px'}}/> */}
                    {/* <NumberInput source="coef_per_km_max" label="Coefficient max. au prix par km (%)" validate={validateNotNegativeNumber} style={{'width':'400px'}}/>
                <NumberInput source="coef_per_min_max" label="Coefficient max. au prix par minute (%)" validate={validateNotNegativeNumber} style={{'width':'400px'}}/> */}
                    {/* <NumberInput source="doc_validation_warning_before" label="Informé pour l'expiration de documents avant (jours)"  
                initialValue={1} style={{'width':'600px'}}
                validate={validateNotSmallerThanOneNumber}/> */}
                </FormTab>
                <FormTab label="Commande">

                    {/* <TextInput fullWidth source="manual_dispatch_postal_codes" style={{'width':'50%'}} helperText="Exemple: 13001-13999, 14000, 14007. Tandis que 13001-13999 pour les codes postaux de 13001 à 13000. 14000 et 14007 sont des valuers individuelles. Séparées par des virgules"
                    label="Zones (codes postaux) pour le dispatching manuel"/> */}
                    <NumberInput source="timeout_order_wait_for_accept" label="Délai d'acceptation (secondes)" validate={validateNotSmallerThan60} style={{ 'width': '600px' }} />
                    {/* <NumberInput source="order_free_wait_min" label="Temps d'attente gratuit (minutes)" validate={validateNotNegativeNumber} style={{'width':'600px'}}/> */}
                    {/* <NumberInput source="wait_price" label="Prix d'attente (€/minute)" initialValue={0} validate={required()} style={{'width':'600px'}}/> */}
                    {/* <NumberInput source="cancel_free_driver" label="Temps d'annulation gratuit(s) du chauffeur" validate={required()} style={{'width':'600px'}}/>
                <NumberInput source="cancel_free_client" label="Temps d'annulation gratuit(s) du client" validate={required()} style={{'width':'600px'}}/> */}

                    {/* <NumberInput source="service_avail_center_lat" label="Latitude du centre du service" validate={[required()]} style={{'width':'25%'}}/>
                <NumberInput source="service_avail_center_lng" label="Longitude du centre du service" validate={[required()]} style={{'width':'25%'}}/>
                <NumberInput source="service_avail_radius" label="Rayon du service(km)" validate={validateNotNegativeNumber} style={{'width':'25%'}}/>
                 */}

                    {/* <Typography variant="h6" gutterBottom>Immédiate</Typography> */}

                    <NumberInput source="redispatch_not_topick" label="Réservation - Délai avant réassignation de la commande (minutes)" validate={validateRedispatch} style={{ 'width': '600px' }} />
                    <NumberInput source="automatic_launching" label="Réservation - Délai pour lancer automatiquement (minutes)" validate={validateNotSmaller45} style={{ 'width': '600px' }} />

                    <NumberInput source="pick_up_radius" label="Rayon autour point de départ(km) pour recevoir la commande" validate={required()} style={{ 'width': '600px' }} />
                    <NumberInput source="show_client_radius" label="Rayon d'affichage du client(m)" validate={required()} style={{ 'width': '600px' }} />
                    {/* <NumberInput source="wait_radius" label="Rayon autour point de départ(m) pour l'attente" validate={required()} style={{'width':'600px'}}/>
                <NumberInput source="dispose_radius" label="Rayon autour point de dépose(m) pour l'alerte" validate={required()} style={{'width':'600px'}}/> */}
                    {/* <NumberInput source="eco_immediate_5" label="Prise en charge Eco - Immédiate moins que 5km (€)" validate={required()} style={{'width':'600px'}}/> 
                <NumberInput source="eco_immediate_10" label="Prise en charge Eco - Immédiate moins que 10km (€)" validate={required()} style={{'width':'600px'}}/> 
                <NumberInput source="eco_immediate_greater_10" label="Prise en charge Eco - Immédiate plus que 10km (€)" validate={required()} style={{'width':'600px'}}/>  */}
                    <NumberInput source="eco_immediate_zone1" label="Prise en charge Eco - Immédiate - Zone 1 (€)" validate={required()} style={{ 'width': '600px' }} />
                    <NumberInput source="eco_immediate_zone2" label="Prise en charge Eco - Immédiate - Zone 2 (€)" validate={required()} style={{ 'width': '600px' }} />
                    <NumberInput source="eco_immediate_zone3" label="Prise en charge Eco - Immédiate - Zone 3 (€)" validate={required()} style={{ 'width': '600px' }} />

                    <NumberInput source="soonest_reservation_time" label="Reservation possible au plus tôt (minutes)" validate={validateNotNegativeNumber} style={{ 'width': '600px' }} />
                    <NumberInput source="latest_reservation_time" label="Reservation possible au plus tard (minutes)" validate={validateNotNegativeNumber} style={{ 'width': '600px' }} />
                    {/* <NumberInput source="reservation_free_cancel_time" label="Réservation- Annulation gratuite avant la prise en charge (minutes)" validate={required()} style={{'width':'600px'}} disabled/> 
                <NumberInput source="immediate_free_cancel_time" label="Immmédiate - Annulation gratuite après l'acceptation (minutes)" validate={required()} style={{'width':'600px'}}/>  */}
                    <NumberInput source="wait_max" label="Attente payante max. (minutes)" validate={required()} style={{ 'width': '600px' }} />
                    <BooleanInput source="show_order_number" label="Afficher le numéro de commande" />
                    {/* <NumberInput source="wait_fee" label="Frais d'attente (€/minute)" validate={required()} style={{'width':'200px'}}/>  */}
                    {/* <NumberInput source="wait_more_30" label="Frais d'attente de 30 min à 1 h (€)" validate={required()} style={{'width':'600px'}}/>  */}

                    {/* <Typography variant="h6" gutterBottom>Réservation à l'avance</Typography> */}

                </FormTab>
                <FormTab label="TOP chauffeurs">
                    {/* <TextInput source="currency_code" label="Code de monnaie"/>
                <TextInput source="currency_symbol" label="Symbole de monnaie"/> */}

                    {/* <NumberInput source="top_duration" label="Période (jours) " validate={validatePositive} style={{'width':'600px'}}/> */}
                    <NumberInput source="top_order_completed" label="Nombre minimum de courses complétées" validate={validatePositive} style={{ 'width': '600px' }} />
                    <NumberInput source="top_cancel_rating" label="Taux d'annulation max. (%)" validate={validatePositive} style={{ 'width': '600px' }} />
                    <NumberInput source="top_parrain_max" label="Nombre max. de parrainage" validate={validatePositive} style={{ 'width': '600px' }} />
                    {/* <NumberInput source="top_commission_reduced" label="Commission réduite (%)" validate={validateNotNegativeNumber} style={{'width':'600px'}}/> */}
                    {/* <NumberInput source="top_commission_reduced_duration" label="Période de réduction (jours) " validate={validatePositive} style={{'width':'600px'}}/> */}
                </FormTab>

                {/* <FormTab label="Code de réduction">
                <TextInput source="first_order_promo_code_value" label="Valeur du code de réduction pour le nouveau compte client" fullWidth validate={required()}/>
                
            </FormTab> */}
                {/* <FormTab label="Comptes">
                <TextInput source="system.mail.sender" label="Expéditeur" validate={required()}/>
                <TextInput source="system.mail.receiver" label="Destinataire" validate={required()}/>
            </FormTab> */}
                <FormTab label="Bonus parrainage">
                    {/* <TextInput source="currency_code" label="Code de monnaie"/>
                <TextInput source="currency_symbol" label="Symbole de monnaie"/> */}
                    <NumberInput source="bonus_day" label="Délai (jours)" validate={validateNotNegativeNumber} style={{ 'width': '600px' }} />
                    <NumberInput source="bonus_max_order" label="Nombre de courses" validate={validateNotNegativeNumber} style={{ 'width': '600px' }} />
                    <NumberInput source="bonus_amount" label="Bonus (€)" validate={validateNotNegativeNumber} style={{ 'width': '600px' }} />

                </FormTab>

                <FormTab label="Coordonnées e-Tcar">
                    <TextInput source="company_name" label="Nom" validate={required()} fullWidth />
                    <TextInput source="company_address_line1" label="Adresse ligne 1" validate={required()} fullWidth multiline />
                    <TextInput source="company_address_line2" label="Adresse ligne 2" validate={required()} fullWidth multiline />
                    <TextInput source="company_email" label="Email" />
                    <TextInput source="company_phone" label="Téléphone" />
                    <TextInput source="contact_email" label="Email de contact" />
                    <TextInput source="register_email" label="Email pour l'inscription" />
                    <TextInput source="admin_email" label="Email pour l'admin" />
                </FormTab>


            </TabbedForm>


        </Edit>
    );
};

export default EditConfig;
