import { fetchUtils } from 'react-admin';
import {BACKEND_API_KEY} from './variant'

export const validateTimeFormat = (value, allValues) => {
    // console.log("All values " + allValues['oc_from_mon']);
    if (value.length !== 5) {
        return 'Doit être au format ab:xy, par exemple: 06:15';
    }

    const vals = value.split(":");
    if(vals.length !== 2){
        return 'Doit être au format ab:xy, par exemple: 06:15';
    }
    
    if(isNaN(+vals[0]) || isNaN(+vals[1])){
        return 'Doit être au format ab:xy tandis que ab et xy sont des nombres, par exemple: 06:15';
    }

    if(+vals[0] < 0 || +vals[0] > 23){
        return "Doit être au format ab:xy tandis que ab est l'heure du jour, de 00 à 23";
    }

    if(+vals[1] < 0 || +vals[1] > 59){
        return "Doit être au format ab:xy tandis que xy est la minute, de 00 à 59";
    }

    
    return [];
};

export const normalizeStripeConnectDues = (dues) => {
      var newDues = dues.filter(function(due) {
          return (due === 'verification.additional_document' ||
          due === 'verification.document' ||
          due === 'company.directors_provided' ||
          due === 'company.executives_provided' ||
          due === 'company.owners_provided' ||
          due === 'representative.last_name' ||
          due === 'company.tax_id' ||
          due === 'company.registration_number' ||
          due === 'external_account');
      });

      return newDues;
  }
  export const getStripeConnectDueName = (due) => {
    if(due === 'verification.additional_document')
        return 'Justificatif de domicile';

    if(due === 'verification.document')
        return "Pièce d'identité";

    if(due === 'company.directors_provided')
        return 'Infos des Directeurs Généraux de la société';

    if(due === 'company.executives_provided'){
        return 'Infos des Cadres de la société';
    }
    if(due === 'company.owners_provided'){
        return 'Infos des Associés de la société';
    }

    if(due === 'representative.last_name'){
        return 'Infos du Représentant légal de la société';
    }
    if(due === 'company.tax_id'){
        return 'Numéro de TVA intracommunautaire';
    }

    if(due === 'company.registration_number'){
        return 'SIREN';
    }

    if(due === 'external_account'){
        return 'IBAN';
    }
    
    // if(due === 'directors.dob.day'){
    //     return 'Date de naissance du Directeur Général: Jour';
    // }
    
    // if(due === 'directors.dob.month'){
    //     return 'Date de naissance du Directeur Général: Mois';
    // }
    // if(due === 'directors.dob.year'){
    //     return 'Date de naissance du Directeur Général: Année';
    // }
    
    // if(due === 'directors.first_name'){
    //     return 'Prénom du Directeur Général';
    // }
    
    // if(due === 'directors.last_name'){
    //     return 'Nom du Directeur Général';
    // }
    
    // if(due === 'executives.address.city'){
    //     return 'Ville du Cadre';
    // }
    
    // if(due === 'executives.address.line1'){
    //     return 'Adresse du Cadre';
    // }
    
    // if(due === 'executives.address.postal_code'){
    //     return 'Code postal du Cadre';
    // }
    
    // if(due === 'executives.dob.day'){
    //     return 'Date de naissance du Cadre: Jour';
    // }
    
    // if(due === 'executives.dob.month'){
    //     return 'Date de naissance du Cadre: Mois';
    // }
    // if(due === 'executives.dob.year'){
    //     return 'Date de naissance du Cadre: Année';
    // }
    
    // if(due === 'executives.first_name'){
    //     return 'Prénom du Cadre';
    // }
    
    // if(due === 'executives.last_name'){
    //     return 'Nom du Cadre';
    // }
    
    // if(due === 'external_account'){
    //     return 'IBAN';
    // }
    
    // if(due === 'owners.address.city'){
    //     return 'Ville du Président Directeur Général';
    // }
    
    // if(due === 'owners.address.line1'){
    //     return 'Adresse du Président Directeur Général';
    // }
    
    // if(due === 'owners.address.postal_code'){
    //     return 'Postal code du Président Directeur Général';
    // }
    
    // if(due === 'owners.dob.day'){
    //     return 'Date de naissance du Président Directeur Général: Jour';
    // }
    
    // if(due === 'owners.dob.month'){
    //     return 'Date de naissance du Président Directeur Général: Mois';
    // }
    
    // if(due === 'owners.dob.year'){
    //     return 'Date de naissance du Président Directeur Général: Année';
    // }
    
    // if(due === 'owners.first_name'){
    //     return 'Prénom du Président Directeur Général';
    // }
    
    // if(due === 'owners.last_name'){
    //     return 'Nom du Président Directeur Général';
    // }
    // if(due === 'representative.address.city'){
    //     return 'Ville du Représentant légal';
    // }
    
    // if(due === 'representative.address.line1'){
    //     return 'Adresse du Représentant légal';
    // }
    
    // if(due === 'representative.address.postal_code'){
    //     return 'Code postal du Représentant légal';
    // }
    
    // if(due === 'representative.dob.day'){
    //     return 'Date de naissance du Représentant légal: Jour';
    // }
    
    // if(due === 'representative.dob.month'){
    //     return 'Date de naissance du Représentant légal: Mois';
    // }
    
    // if(due === 'representative.dob.year'){
    //     return 'Date de naissance du Représentant légal: Année';
    // }
    
    // if(due === 'representative.first_name'){
    //     return 'Prénom du Représentant légal';
    // }

    // if(due === 'representative.last_name'){
    //     return 'Nom du Représentant légal';
    // }
    
    // if(due === 'representative.relationship.executive'){
    //     return '';
    // }

    return "";
  }

export const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    // const userType = localStorage.getItem('user_type');
    const sessionId = localStorage.getItem('session_id');
    console.log("Session id: " + sessionId);
    options.headers.set('session-id', sessionId);
    // options.headers.set('user-type', userType);

    if(url.includes('?'))
        url = url + "&app_key=" + BACKEND_API_KEY;
    else
        url = url + "?app_key=" + BACKEND_API_KEY;
    console.log("Request to url: " + url);
    console.log("Options: " + JSON.stringify(options));
    return fetchUtils.fetchJson(url, options);
  }

  
  export const getCurrentYear = () => {
      let currentYear = new Date().getFullYear();
      console.log('Current year ' + currentYear);
      return currentYear;
  }

  export const getCurrentMonth = () => {
    let currentMonth = new Date().getMonth() + 1;
    console.log('Current month ' + currentMonth);
    return currentMonth;
}

  export const getMonthOfYear = (year) => {
    if (typeof year === 'undefined' || !year)
        return [];

    var maxMonth = 12;
    if(year === getCurrentYear())
        maxMonth = getCurrentMonth();
    
    var months = [];
    for (var i = 1; i <= maxMonth; i++) 
        months.push({id: i, name: i});

    return months;
}

export const getWeeksOfMonth = (month, year) => {
    if (typeof month === 'undefined' || !month || 
        typeof year === 'undefined' || !year )
        return [];

    let weekNumber = getStartWeekNumber(month - 1, year);
    // let weeks = getWeeksStartAndEndInMonth(month - 1, year, 1);
    var date = new Date(year, month - 1, 1);
    var weekCount = 0;
    while(weekCount < 6){
        date.setDate(date.getDate() + 7);
        console.log("Date " + date + ' day: ' + date.getDay());
        if(date.getMonth() > month - 1){
            if(date.getDay() !== 1){//If this is not monday so it is in the last week of the month
                weekCount++;
                break;
            }
        }
            
        weekCount++;
    }
    
    var weekNumbers = [];
    // weekNumbers.push({id: weekNumber + weekCount, name: weekNumber + weekCount});
    for (var i = weekNumber; i <= weekNumber + weekCount; i++) 
        weekNumbers.push({id: i, name: i});

    return weekNumbers;
}

export const getYears = () => {
    var years = [];
    for (var i = getCurrentYear(); i >= 1945; i--) 
        years.push({id: i, name: i});

    return years;
}

export const getStartWeekNumber = (month, year) => {
     var tdt = new Date(year, month, 1);
     var dayn = (tdt.getDay() + 6) % 7;
     tdt.setDate(tdt.getDate() - dayn + 3);
     var firstThursday = tdt.valueOf();
     tdt.setMonth(0, 1);
     if (tdt.getDay() !== 4) {
        tdt.setMonth(0, 1 + ((4 - tdt.getDay()) + 7) % 7);
     }
     return 1 + Math.ceil((firstThursday - tdt) / 604800000);
}

export const endFirstWeek = (firstDate, firstDay) => {
    if (! firstDay) {
        return 7 - firstDate.getDay();
    }
    if (firstDate.getDay() < firstDay) {
        return firstDay - firstDate.getDay();
    } else {
        return 7 - firstDate.getDay() + firstDay;
    }
}

export const getWeeksStartAndEndInMonth = (month, year, start) => {
    let weeks = [],
        firstDate = new Date(year, month, 1),
        lastDate = new Date(year, month + 1, 0),
        numDays = lastDate.getDate();

    // let start = 1;
    let end = endFirstWeek(firstDate, 2);
    while (start <= numDays) {
        weeks.push({start: start, end: end});
        start = end + 1;
        end = end + 7;
        end = start === 1 && end === 8 ? 1 : end;
        if (end > numDays) {
            end = numDays;
        }
    }
    return weeks;
}

export const getUrlExtension = ( url ) => {
    return url.split(/\#|\?/)[0].split('.').pop().trim();
}

export const generateRandom = (length) => {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
       result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}