import React, {Fragment} from 'react';
import { List, Datagrid, TextField, ImageField, DateField, SelectInput, EditButton, Filter, TextInput, useTranslate } from 'react-admin';
import BatchDeleteButton from '../../control/button/batch-delete-button';
import {locationDict, locationTypes} from '../../util/variant'
import {ValidateField, LockField, AvatarField} from '../../control/field/index'

const BulkActionButtons = props => (
    <Fragment>
        {/* <BatchDeleteButton label="Supprimer" {...props} /> */}
    </Fragment>
);
const Title = ({ record }) => {
    const translate = useTranslate();
    return <span>{translate('resources.option.list')}</span>;
};

// const TypeTextField = ({ source, record = {}, label}) => {
//     const locationType = locationDict[record.type];
//     return <span class="MuiTypography-root MuiTypography-body2">{typeof(locationType) !== 'undefined' ? locationType.name : record.type}</span>;
// }
    
// TypeTextField.defaultProps = {
//     addLabel: true,
// };

const statusList = [
    {id: 0, name: 'Non-vérrouillé'},
    {id: 1, name: 'Vérrouillé'}
];

const DriverFullNameField = ({ record = {}, }) => (
    <div style={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center' }}>
        {record.first_name}&nbsp;{record.last_name}({record.email})
    </div>
);

const GodChildFullNameField = ({ record = {}, }) => (
    <div style={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center' }}>
        {record.c_first_name}&nbsp;{record.c_last_name}({record.c_email})
    </div>
);


const ListFilter = ({ permissions, ...props })  => (
    <Filter {...props}>
        {/* <TextInput label="Name" source="name" alwaysOn resettable/> */}
        {/* <SelectInput label="État" source="is_locked" alwaysOn choices={statusList} 
        allowEmpty emptyText="Tous" emptyValue='-1' initialValue='-1'/> */}
        
    </Filter>
);

const ListOption = ({permissions, ...props}) => (
    <List title={<Title/>} {...props} 
        filters = {<ListFilter permissions={permissions} />} 
        bulkActionButtons={<BulkActionButtons />} exporter={false}>
        <Datagrid rowClick="">
            {/* <AvatarField source="photo_url" label="" size='200'/> */}
            <GodChildFullNameField label="Filleul"/>
            <DriverFullNameField label="Parrain"/>
            <TextField source="order_count" label="Nombre de courses complétées"/>
            <TextField source="amount" label="Bonus à réserver (€)"/>
            <TextField source="remain_days" label="Jours restants"/>
        </Datagrid>
    </List>
);

export default ListOption;

