import React, {Fragment} from 'react';
import { ReferenceField, List, ReferenceArrayField,SingleFieldList, Datagrid, TextField, ReferenceInput, SelectInput, EditButton, Filter, TextInput, useTranslate } from 'react-admin';
import BatchDeleteButton from '../../control/button/batch-delete-button';
import {orderTypes} from '../../util/constant'
import {OrderTypeField, OrderTypeFilter, FormattedDateField} from '../../control/field/index'
import { makeStyles, withStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
    colorPrimary: {
        color:'#F4004E',
    },
    largeWidth: {width: '200px'},
});

const BulkActionButtons = props => (
    <Fragment>
        <BatchDeleteButton label="Supprimer" {...props} content="Voulez-vous supprimer ces prix ?"/>
    </Fragment>
);
const Title = ({ record }) => {
    const translate = useTranslate();
    return <span>{translate('resources.price.list')}</span>;
};

const CancelAmountField = ({ record = {}}) => {
    var cancel = record['cancel'];
    if(cancel === 'undefined' || typeof(cancel) === 'undefined' || cancel === '' || cancel === null){
        return <div/>;
    }
    var cancelUnit = record['cancel_unit'] === "0" ? "€" : "%";
    return <span class="MuiTypography-root MuiTypography-body2">{cancel}{cancelUnit}</span>;
}
    
CancelAmountField.defaultProps = {
    addLabel: true,
};

const NameField = ({ record = {}, source}) => {
    return <span class="MuiTypography-root MuiTypography-body2">{record[source]}&nbsp;</span>;
}
    
NameField.defaultProps = {
    addLabel: true,
};

const ListFilter = withStyles({name: {width: 200}})(({ permissions, ...props })  => {
    const classes = useStyles();
    return (<Filter {...props}>
    
        {/* <TextInput label="Name" source="name" alwaysOn resettable/>
        <TextInput label="Address" source="address" alwaysOn resettable/>
        <SelectInput label="Type" source="type" alwaysOn choices={locationTypes}
            allowEmpty emptyText="Tous les types" emptyValue='0' initialValue='0'
        /> */}
        <SelectInput label="Type de commande" source="order_type" alwaysOn choices={orderTypes} 
            optionText="name" emptyText="Tous les types" emptyValue='-1' 
            initialValue='-1'
            />
        <ReferenceInput source="vehicle_type_id" perPage={99999}  alwaysOn
        emptyText="Tous les types"
            reference="vehicle_type" label="Type de véhicule">
                <SelectInput optionText="name" />
            </ReferenceInput>
            {/* <ReferenceInput source="vehicle_class_id" perPage={99999} alwaysOn className={classes.largeWidth}
            reference="vehicle_class" label="Gamme de véhicule">
                <SelectInput optionText="name"/>
            </ReferenceInput> */}
        
    </Filter>
)}); 

const ListPrice = ({permissions, ...props}) => {
    return <div>
    <span class="MuiTypography-root MuiTypography-body2">Remarque: Si un triplet Type de commande, Type de véhicule, Zone apparaissent dans multiple configurations, alors la dernière mise à jour sera appliquée</span>
    <List title={<Title/>} {...props} 
        filters = {<ListFilter permissions={permissions} />} 
        bulkActionButtons={<BulkActionButtons />} exporter={false}>
        
        <Datagrid rowClick="edit">
            {/* <TextField source="name" label = "Zone"/>
            <TextField source="from_km" label = "Rayons de (km)"/>
            <TextField source="to_km" label = "Rayons à (km)"/> */}
            {/* <OrderActivityTypeField label="Type d'activité"/> */}
            {/* <ReferenceField label="Zone" source="zone_id" reference="zone" link="">
                    <TextField source="full_name" />
                </ReferenceField> */}
                
                <ReferenceArrayField label="Types de véhicule" reference="vehicle_type" source="vehicle_type_ids" basePath="" linkType="">
                <SingleFieldList linkType="">
                {/* <FunctionField label="" render={record => `${record.first_name} ${record.last_name} (${record.email})`} /> */}
                <NameField source="name" linkType=""/>
                </SingleFieldList>
            </ReferenceArrayField>
            <OrderTypeField source="order_type" label="Type de commande"/>
                <ReferenceArrayField label="Zones" reference="zone" source="zone_ids" basePath="" linkType="">
                <SingleFieldList linkType="">
                {/* <FunctionField label="" render={record => `${record.first_name} ${record.last_name} (${record.email})`} /> */}
                <NameField source="full_name" linkType=""></NameField>
                </SingleFieldList>
            </ReferenceArrayField>
            
            {/* <ReferenceField label="Type de véhicule" source="vehicle_type_id" reference="vehicle_type" link="">
                    <TextField source="name" />
                </ReferenceField> */}
            
                {/* <TextField source="base" label = "Base(€)"/> */}
            <TextField source="min" label="Minimum (€)"/>
            {/* <TextField source="min_sub" label="Minimum abonné(€)"/> */}
            <TextField source="per_km" label="Par km (€)"/>
            {/* <TextField source="per_km_sub" label="Par km abonné (€)"/> */}
            <TextField source="per_minute" label="Par minute (€)"/>
            {/* <TextField source="per_minute_sub" label="Par minute abonné (€)"/> */}
            {/* <TextField source="wait" label="Frais d'attente (€/minute)"/> */}
            {/* <TextField source="free_wait_time" label="Attente gratuite (minute)"/> */}
            {/* <CancelAmountField label="Frais d'annulation"/> */}
            {/* <TextField source="max_cancel" label="Plafond du frais d'annulation (€)"/> */}
            <FormattedDateField source="update_time" label="Dernière mise à jour"  locales="fr-FR" format="format_date_time" />
            {/* <TextField source="wait" label="Attente(€/minute)"/>
            <TextField source="markup" label="Supplément(€)"/> */}
            {/* <TextField source="quantity_markup" label="Supplément au dela 3 passagers(€/passager)"/> */}
        </Datagrid>
    </List></div>;
};

export default ListPrice;

